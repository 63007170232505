@font-face {
	font-family: 'Aquire';
	src: local('Aquire'),
		url('../fonts/AquireLight.otf') format('otf');
}

@font-face {
	font-family: 'Comfortaa';
	src: local('Comfortaa'),
		url('../fonts/Comfortaa-Medium.ttf') format('ttf');
}

h1 {
	font-family: 'Aquire';
	font-size: 27px;
	color: #ffc107ff;
}

p {
	font-family: 'Comfortaa';
	font-size: 18px;
	color: white;
	line-height: 1.6em;
}

a {
	font-family: 'Comfortaa';
	font-size: 18px;
	color: #ffc107ff;
}

.CarboMetro-Writing {
	font-family: 'Aquire';
	font-size: 18px;
	color: #ffc107ff;
}

.CarboMetro-Writing-Footer {
	font-family: 'Aquire';
	font-size: 18px;
	color: #000000;
}

.CarboMetro-Slogan-Section {
	font-family: 'Aquire';
	font-size: 52px;
	color: white;
	display: flex;
	align-items: center;
	justify-content: center;
	padding-top: 30px;
	padding-bottom: 30px;
}

.App-CarboMetro {
	background-color: #222222;
	/* min-height: 100vh; */
	min-height: 100dvh;
	display: flex;
	flex-direction: column;
	align-items: start;
	justify-content: start;
	padding-left: 80px;
	padding-right: 80px;
	padding-top: 30px;
	font-size: 18px;
	color: white;
}


.app-header-carbo-metro {
	min-width: calc(100dvw - 160px);
	min-height: 50px;
}

.app-logo-carbo-metro {
	width: 200px;
	height: 200px;
	padding-top: 0px;
	padding-bottom: 0px;
}

.app-logo-scritta-mereghetti-carbo-metro {
	height: 40px;
	padding-bottom: 10px;
}

.app-logo-mereghetti-carbo-metro {
	width: 100px;
	height: 100px;
	padding-top: 10px;
	padding-bottom: 10px;
}

/* Iubenda */

.iub__us-widget {
	background-color: #222222;
	border-top: #ffc107ff 1px solid;
	align-items: flex-start !important;
	/* position: "fixed", */
	/* bottom: 0,
	width: "100%",
	height: '40px',
	padding: "7px", */
	height: 90px;
}

.iub__us-widget .iub__us-widget__wrapper {
	background-color: #222222;
}

/* MUI */
.MuiMenu-paper {
	background-color: #ffc107ff !important;
}

.MuiTypography-root {
	font-family: 'Aquire' !important;
	font-size: 18px;
	color: black !important;
	/* line-height: 1.6em; */
}

.MuiButton-text {
	color: black !important;
	font-size: 18px !important;
	/* font-weight: 900 !important; */
	font-family: Aquire !important;
}